import Header from "../components/Header";
import Footer from "../components/Footer";
import '../App.css'

export default function Home() {
    return (
        <div className=" bg-black h-svh">
            <Header/>


            <div className=" flex justify-center items-center h-screen">
                <h1 className=" text-white text-7xl">Kommer snart</h1>
            </div>


            <Footer/>
        </div>
    )
}