import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Nopage from './pages/Nopage';

function App() {
  return (

      <div className='App'>
        <BrowserRouter>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="*" element={<Nopage/>}/>
        </Routes>
        </BrowserRouter>
      </div>

  );
}

export default App;
